.advanced-features {
    display: flex;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 25px;
    gap: 20px;
    padding: 0 20px;
}
.advanced-features__icon-container{
    min-width:70px;
    min-height: 70px;
}
.features-icon {
    background-color: #b53729;
    font-size:50px;
    text-align: center;
    max-height: 70px;
    max-width: 70px;
    border: solid #b53729;
    border-radius: 10px;
    color: white;
    line-height: 70px;
}

.features-content h4 {
    color: #444444;
    font-size: 18px;
}
.features-content p {
    color: #939399;
    font-size: 18px;
}
hr {
    color: #474747;
}
