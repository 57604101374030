.footer {
    background-color: #222326;

}

.footer_info {

    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    justify-content: space-evenly;
        gap: 10px;
        flex-wrap: wrap;
}

.info_colum {
    flex: 1;
    padding: 0 15px;
    font-size: 14px;
    color: #bebebe;
    min-width: 230px;
    line-height: 25px;
}

.info_colum h3 {
    color: white;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--primary-font);
    margin-bottom: 23px;
}

.info_colum .contact-info {
    all: unset;
    list-style: none;
}

.info_colum .contact-info li {
    display: flex;
    margin-bottom: 10px;
}

.info_colum .contact-into_icon {
    display: block;
    font-size: 16px;
    line-height: 26px;
    color: #bebebe;
    padding-top: 5px;
    padding-right: 10px;
    padding-left: 10px;

}

.info_colum .phones {
    padding-left: 0;
}

.info_colum .phones li {
    margin-bottom: 2px;
}

.info_colum .contact-info li strong {
    padding-right: 10px;
}

.footer_navlinks {
    display: flex;
    flex-direction: column;
    color: #bebebe;
}

.footer_navlinks * {
    color: #bebebe;
}

.footer_published {
    min-height: 55px;
    text-align: center;
    background-color: #1e1d1d;
    color: #999999;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}