.country-section_container {
    display: flex;

}

.country-section_container .county-flag {
    max-width: 35px;
    max-height: 23px;
    margin-right: 1em;
}

.country-section_info h1 {
    margin-right: 10px;
    margin-top: 0;
    color: #b53729;
    font-size: 1.35rem;
    font-weight: 700;
    font-family: var(--primary-font);

}

.country-section_cities {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    flex-wrap: wrap;
}