/*
    When inputs are focused, the floating label position itself 
    depending on the resolutions of the screen.
*/

/* Mobile devices*/
@media (max-width: 480px) 
{
    .input__area__wrp .label__floating 
    {
        top: 35%;
        left:25%;
    }
    
    .input__area__wrp .label__floating--name 
    {
        top: 40%;
        left:5%;
    }
    
    .input__area__wrp .label__floating--textarea 
    {
        top: 25%;
        left:25%;
    }

    .input__area__wrp input:focus ~ .label__floating--name,
    .input__area__wrp input:not(:focus):valid ~ .label__floating--name
    {
        top: 15%;
        left: 5%;
    }
    
    .input__area__wrp input:focus ~ .label__floating,
    .input__area__wrp input:not(:focus):valid ~ .label__floating
    {
        top: 10%;
        left: 25%;
    }
    
    .input__area__wrp textarea:focus ~ .label__floating--textarea,
    .input__area__wrp textarea:not(:focus):valid ~ .label__floating--textarea
    {
        top: 10%;
        left: 25%;
    }
}

        
/* Low resolution Tablets and iPads */
@media (min-width: 481px) and (max-width: 850px) 
{
    .input__area__wrp .label__floating 
    {
        top: 35%;
        left:15%;
    }
    
    .input__area__wrp .label__floating--name 
    {
        top: 40%;
        left:5%;
    }

    .input__area__wrp .label__floating--textarea 
    {
        top: 30%;
        left:15%;
    }

    .input__area__wrp input:focus ~ .label__floating--name,
    .input__area__wrp input:not(:focus):valid ~ .label__floating--name
    {
	top: 15%;
	left: 5%;
    }

    .input__area__wrp input:focus ~ .label__floating,
    .input__area__wrp input:not(:focus):valid ~ .label__floating
    {
        top: 10%;
        left: 15%;
    }

    .input__area__wrp textarea:focus ~ .label__floating--textarea,
    .input__area__wrp textarea:not(:focus):valid ~ .label__floating--textarea
    {
        top: 15%;
        left: 15%;
    }
}


        
/* Tablets iPads (Portrait) */
@media (min-width: 851px) and (max-width: 1024px)
{
    .input__area__wrp .label__floating 
    {
        top: 35%;
        left:25%;
    }
    
    .input__area__wrp .label__floating--name 
    {
        top: 40%;
        left:5%;
    }

    .input__area__wrp .label__floating--textarea 
    {
        top: 20%;
        left:25%;
    }

    .input__area__wrp input:focus ~ .label__floating--name,
    .input__area__wrp input:not(:focus):valid ~ .label__floating--name
    {
        top: 15%;
        left: 5%;
    }

    .input__area__wrp input:focus ~ .label__floating,
    .input__area__wrp input:not(:focus):valid ~ .label__floating
    {
        top: 10%;
        left: 25%;
    }

    .input__area__wrp textarea:focus ~ .label__floating--textarea,
    .input__area__wrp textarea:not(:focus):valid ~ .label__floating--textarea
    {
        top: 5%;
        left: 25%;
    }
}

    
/* Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1199px)
{
    .input__area__wrp .label__floating 
    {

        top: 35%;
        left:25%;
    }
    
    .input__area__wrp .label__floating--name 
    {
        top: 40%;
        left:5%;
    }

    .input__area__wrp .label__floating--textarea 
    {
        top: 20%;
        left:25%;
    }

    .input__area__wrp input:focus ~ .label__floating--name,
    .input__area__wrp input:not(:focus):valid ~ .label__floating--name
    {
        top: 15%;
        left: 5%;
    }

    .input__area__wrp input:focus ~ .label__floating,
    .input__area__wrp input:not(:focus):valid ~ .label__floating
    {
        top: 10%;
        left: 30%;
    }

    .input__area__wrp textarea:focus ~ .label__floating--textarea,
    .input__area__wrp textarea:not(:focus):valid ~ .label__floating--textarea
    {
        top: 5%;
        left: 30%;
    }
}
    
/* Big Monitors */
@media (min-width: 1200px) {
    .input__area__wrp .label__floating--name 
    {
        top: 40%;
        left:5%;
    }

    .input__area__wrp .label__floating 
    {
        top: 35%;
        left:25%;
    }

    .input__area__wrp .label__floating--textarea 
    {
        top: 20%;
        left:25%;
    }
    
    .input__area__wrp input:focus ~ .label__floating--name,
    .input__area__wrp input:not(:focus):valid ~ .label__floating--name
    {
        top: 15%;
        left: 5%;
    }
    
    .input__area__wrp input:focus ~ .label__floating,
    .input__area__wrp input:not(:focus):valid ~ .label__floating
    {
        top: 10%;
        left: 25%;
    }

    .input__area__wrp textarea:focus ~ .label__floating--textarea,
    .input__area__wrp textarea:not(:focus):valid ~ .label__floating--textarea
    {
        top: 5%;
        left: 25%;
    }
}
