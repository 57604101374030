.tabs {
  display: flex;
  flex-wrap: wrap;
  /* margin-right: 15%; */
  background: #fafafa;
}

.input {
  position: absolute;
  opacity: 0;
}
.label {
  flex: 1;
  width: 100%;
  padding: 10px ;
  background: #fafafa;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  transition: background 0.3s, color 0.1s;
  border-right: 1px solid #e5eaea;
  border-left: 1px solid #e5eaea;
  box-sizing: border-box;
  

}
.label:hover {
  background: #d8d8d8;
}
.label:active {
  background: #ccc;
}
.input:focus + .label {
  z-index: 1;
}
.input:checked + .label {
  background: #fff;
  /* color: #000; */
  border-right: 1px solid #bdc3c7;
  border-left: 1px solid #bdc3c7;
  color: #b53729;
  /* border-top: 2.5px solid #b53729; */
}
.panel {
  /* flex: 1; */
  width: 100%;
  display: none;
  padding: 20px 30px 30px;
  background: #fafafa;
  border-top: 1px solid #e5eaea;
  
}

.input:checked + .label + .panel {
  display: block;
}
