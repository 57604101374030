 /* Changing the order for the sidebar and the article after resizing the screen */
 @media screen and (min-width: 850px){

    .careers__container{
        flex-direction: row-reverse;
    }
    .careers__container div:first-child {
        order: 1;
    }
    .careers__sidebar{
        margin-right: 10%;
        min-width: 300px;
    }
}

@media screen and (max-width: 850px){
    .careers__container{
        flex-direction: column;
    }
    .careers__sidebar{
        position: relative !important;
        width: 90%;

    }
    .careers__body{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}

/* .careers__body, .careers__sidebar{
    flex-grow: 1;
}

*/

