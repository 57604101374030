.activity-container {
    display: flex;
    flex-wrap: wrap;
    background-color: #f7f7f7 !important;
    border-bottom-color: #ededed !important;
    border-bottom-style: solid !important;
}
.activity-slide {
    width: 100%;
}
.activity-title {
    text-align: center;
    font-size: 30px;
    color: #353535;
    line-height: 1.3;
    font-weight: bold;
}
.cards {
    max-width: 1200px;
    box-sizing: border-box;
    display: flex;
    margin: 0 auto;
    padding: 50px 15px;
    gap: 50px;
}