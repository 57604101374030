@media screen and (min-width: 850px){
    .Industrial{
        flex-direction: row-reverse;
    }
    .Industrial div:first-child {
        order: 1;
    }
}

@media screen and (max-width: 850px){
    .Industrial{
        flex-direction: column;
    }
    .Industrial__sidebar{
        position: relative !important;
        width: 100%;
    }
    .Industrial__content{
        width: 100%;
    }
}
.Industrial__content, .Industrial__sidebar{
    flex-grow: 1;
}