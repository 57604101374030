@media (max-width: 850px){
    .tabs{
      margin-left: 0;
      margin-right: 0;    
      
      
    }
    .panel {
        order: 99;
      }
      .label {
        flex: none;
        
      }
}


@media (min-width: 850px) {
    .tabs{
        min-width: 100%;
      }
    .label {
      width: auto;
    }
  }


  @media (min-width: 850px) {
    .panel {
      order: 99;
    }
  }