@media screen and (min-width: 850px){
    .PharmaciesSector{
        flex-direction: row-reverse;
    }
    .PharmaciesSector div:first-child {
        order: 1;
    }
}

@media screen and (max-width: 850px){
    .PharmaciesSector{
        flex-direction: column;
    }
    .PharmaciesSector__sidebar{
        position: relative !important;
        width: 100%;
    }
    .PharmaciesSector__content{
        width: 100%;
    }
}
.PharmaciesSector__content, .PharmaciesSector__sidebar{
    flex-grow: 1;
}