
.text__font{
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8;
    align-items: center;
    justify-content: center;
}

.break{
    height: 15px;
}


.text__bold{
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 800;
    line-height: 1.8;
}


.content a:hover {
    color: #808080;
}

.content a {
    text-decoration: none;
    color: #1e73be;
    line-height: 1.8;
    transition: color .3s ease-in-out, box-shadow .4s ease-in-out;
}

.article__image{
    max-width: 100%;
}
