.aboutus-card {
    display: flex;
    flex: 1;
    padding: 40px 20px;
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    color: #474747;
    text-align: center;
    flex-direction: column;
    border: 1px solid transparent;
    border-radius: 5px !important;
    background-color: #ffffff;
}

.icn {
    font-size: 60px;
}

.card-content h2 {
    margin-top: 30px;
    margin-bottom: 20px;
    color: #b53729;
    font-size: 18px;
    font-weight: 600;
    font-family: "CustomFont", Arial, Helvetica, sans-serif;
    text-align: center;
}
.card-content p {
    margin-top: 30px;
    margin-bottom: 20px;
    color: #3f3f3f;
    font-size: 16px;
    line-height: 26px;
    font-family: "CustomFont", Arial, Helvetica, sans-serif;
    text-align: center;
}
