.dropdown {
    position: relative;
}

.dropdown-content .navlink {
    all: initial;
    font-family: var(--primary-font);
    font-size: 16px;
    line-height: 70px;
    font-weight: 400;
    cursor: pointer;
    margin: 0 10px;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    border-top: 3px solid #dd4d4d;
    min-width: 240px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    flex-direction: column;
    align-items: center;
}

.dropdown-content .dropdown-item {
    text-align: center;
    min-width: 230px;
    line-height: 35px;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
    font-family: var(--primary-font);
    color: #666666;
    border-bottom: 1px solid #dcdadb;
    text-decoration: none;
}

.dropdown-content .dropdown-item:hover {
    background-color: #c4c4c4;
    color: #ffffff;
}

.dropdown-container .dropdown-content .active {
    display: inline;
    background-color: #c4c4c4;
    color: #ffffff;
}
.dropdown-container:focus .dropdown-content {
    display: flex;
}

.dropdown-container:hover .dropdown-content {
    display: flex;
}

.dropdown-container:active .dropdown-content {
    display: flex;
}

.dropdown-content:hover {
    display: flex;
}