.advanced-section{
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    gap: 50px;
}

.advanced-section h2 {
    font-size: 30px;
    padding-left: 24px;
    color: #b53729;
    text-align: left;
    font-family: Roboto Condensed;
    font-weight: 700;
    font-style: normal;
}

.advanced-section img {
    max-height: 600px;
    padding: 20px;
}
.menu-container button {
    background-color: #b53729;
    color: white;
    padding: 14px 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid transparent;
    margin: 15px;
}

.menu-container button:hover {
    background-color: #5a5462;
}