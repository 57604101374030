.nav {
    width: 100%;
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 99;
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.1);

}

.nav_main-nav {
    width: 100%;
    height: 70px;
    margin: auto;
    max-width: 1170px;
    color: var(--primary-font-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: "#fff";
}

.main-nav_navlinks {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.main-nav_navlinks .navlink {
    all: initial;
    font-family: var(--primary-font);
    font-size: 16px;
    line-height: 70px;
    font-weight: 400;
    cursor: pointer;
    margin: 0 10px;
}

.main-nav_navlinks .navlink:hover {
    color: #7a7a7a;
}

.main-nav_navlinks .active {
    color: #7a7a7a;
    cursor: default;
}


main {
    position: absolute;
    width: 100%;
    /* padding-bottom: 1000px; */
    }
    

/* Collapsed Navbar ____________________________________________________________________ */
.collapsed-navbar button:hover {
    border: none;
    background-color: white;
}
.collapsed-navbar {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 100;
    max-height: 35px;
}

.collapsed-navbar .nav_door_button {
    position: relative;
    border: none;
    box-shadow: none;
    background-color: transparent;
    margin: 0 15px;
    cursor: pointer;

}

.collapsed-navbar .nav_door_button:focus-visible{
    outline: none;
    border: none;
}

.collapsed-navbar .nav-button {
    position: relative;
    width: 35px;
    height: 1px;
    margin: 10px 0;
    background-color: #000;
    transition: all 0.3s ease;
}

.collapsed-navbar .nav-button::before,
.nav-button::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    transition: all 0.3s ease;
    margin: 0  -18px;
}

.collapsed-navbar .nav-button::before {
    transform: translateY(-10px);
}

.collapsed-navbar .nav-button::after {
    transform: translateY(10px);
}

.collapsed-navbar .nav-button.open {
    background-color: transparent;
}

.collapsed-navbar .nav-button.open::before {
    transform: rotate(45deg);
}

.collapsed-navbar .nav-button.open::after {
    transform: rotate(-45deg);
}


.collapsed-navbar ul {
    position: absolute;
    display: none;
    width: 100vw;
    background: white;
    top: 100%;
    border: 1px solid #ddd;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    animation: slide-down 0.3s ease-out forwards;
    transform-origin: top;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
}
.collapsed-navbar ul.english_collapse-nav{
    right: 0;
}
.collapsed-navbar ul.arabic_collapse-nav{
    left: 0;
}

.collapsed-navbar ul li {
    padding: 10px 25px;
    border-bottom: 1px solid #ddd;
}

.collapsed-navbar ul li:last-child {
    border-bottom: none;
}

.collapsed-navbar .navlink {
    all: initial;
    font-family: var(--primary-font);
    font-size: 16px;
    line-height: 40px;
    font-weight: 400;
    cursor: pointer;
    display: block;
    text-align: ltr;
}

.collapsed-navbar .navlink .active {
    color: #7a7a7a;
    cursor: default;
}

.collapsed-navbar ul.open {
    display: block;
}

.collapsed-navbar .collapsed-toggle-menu-button {
    all: initial;
    font-family: var(--primary-font);
    font-size: 16px;
    line-height: 40px;
    font-weight: 400;
    cursor: pointer;
    display: block;
    text-align: ltr;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.collapsed-navbar .collapsed-toggle-menu-list {
    display: none;
    background: white;
    border-bottom: 1px solid #ddd;
    animation: slide-down 0.3s ease-out forwards;
    transform-origin: top;
}

.collapsed-navbar .collapsed-toggle-menu-list li {
    padding: 5px 0 5px 40px;
}

.collapsed-navbar div.open {
    display: block;
}

.collapsed-toggle-menu-list .navlink {
    font-size: 0.90rem;
    font-weight: 100;
}
.collapsed-navbar .lang_button{
    width: 100%;
    text-align: end;
}

/* Animation */
@keyframes slide-down {
    0% {
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}