.solutions-page {
    margin-top: 70px;

}

.solutions-page img {
    width: 100%;
}

.solutions-page h1 {
    text-align: center;
    padding: 5% 0;
    font-size: 2.8rem;
    color: #bc0505;
    line-height: 1em;
    font-weight: 400;
    font-family: var(--primary-font);
}

.solutions_cards {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-around;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 3em;
}