.careers__container{
    display: -webkit-flex;
    display: flex; 
    width:100%;
    margin-top: 5%;
    /* min-height: 900px; */
    color: #474747;
    align-items: flex-start;
    padding-bottom: 5%;
    background-color: #fafafa;
    border-top: 1px solid #d7d7d7;
}

.careers__sidebar{
    /* width: 25%; */
    min-width: 25%;
    max-width: 1300px;
    flex: 1;
    text-align: center;
    /* margin-right: 10%;
    flex: 2;
    margin-left: 10%; */
}

.careers__body{
    flex: 2;
    width: 70%;
    margin-left: 15%;
}


.title--red{
    font-weight: 700;
    color: #b53729;
    font-size: 20px;
    margin-bottom: 1rem;

}

.title--black{
    font-weight: 700;
    color: #474747;
    font-size: 20px;
    margin-bottom: 1rem;

}

.text_style{
    font-weight: 400;
    color: #474747;
    font-size: 18px;
    margin-bottom: 1rem;
}