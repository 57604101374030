.business-sectors {
    background-color: #1d2a33;
    padding: 2rem 0;
}

.business-sectors h1 {
    font-size: 20px;
    color: white;
    text-align: center;
    display: block;
    font-weight: 600;

}

.business-sectors_icon-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    margin: 5rem 0 0 0;
    padding: 0 3rem;
    gap: 10px;
    flex-wrap: wrap;
}
.business-sectors_icon-list h3{
    min-width: 150px;
    height: 9rem;
}