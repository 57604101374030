.MaintenanceSector {
    display: flex;
    max-width: 1200px;
    margin: 100px auto 30px auto;
    min-height: 300px;
    height: auto;
    color: #474747;
    align-items: flex-start;
    padding-bottom: 5%;
    gap: 4rem;
    padding-left: 2%;
}
.MaintenanceSector__sidaber{
    max-width: 1200px;
}