.advantages-section {
    display: flex;
    flex-direction:row;
    align-items: center;
    gap: 50px;
    padding: 0 0.02rem;
    margin: 0.01rem auto;
    max-width: 1200px;
}
.advantages-section_box {
    text-align: center;
    flex: 1;
    
}
.advantages-section_h1 {
    font-size: 1.25em;
    font-weight: 500;
    
}
.advantages-section_p {
    font-size: 1.0625em;
    font-weight: 450;
    
}
@media (max-width: 770px) {
    .advantages-section {
        flex-direction: column; 
        align-items: center; 
    }
    
}

