 /* Changing the order for the sidebar and the article after resizing the screen */
@media screen and (min-width: 850px){

    .container{
        flex-direction: row-reverse;
    }
    .container div:first-child {
        order: 1;
    }
}

@media screen and (max-width: 850px){
    .container{
        flex-direction: column;
    }
    .sidebar{
        position: relative !important;
        width: 100%;
    }
    .article{
        width: 100%;
    }
}

.article, .sidebar{
    flex-grow: 1;
}