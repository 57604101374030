.financial-container {
    width: 100%;
    margin-top: 70px;
}
.financial-container img {
    width: 100%;
}

.financial_content{
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    gap: 5px;
    padding: 0 20px;
        box-sizing: border-box;
}

.financial_content .content_right-side{
    /* min-width: 33.3%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact-page_divider {
    background-color: #474747;
    opacity: 0.2;
    height: 1px;
    width: 100%;
    margin: 40px 0;
}
@media screen and (max-width:1060px) {
    .financial_content {
        flex-direction: column;
    }

}