@media screen and (min-width: 850px){
    .FarmSector{
        flex-direction: row-reverse;
    }
    .FarmSector div:first-child {
        order: 1;
    }
}

@media screen and (max-width: 850px){
    .FarmSector{
        flex-direction: column;
    }
    .FarmSector__sidebar{
        position: relative !important;
        width: 100%;
    }
    .FarmSector__content{
        width: 100%;
    }
}
.FarmSector__content, .FarmSector__sidebar{
    flex-grow: 1;
}