.solutions-card {
    display: flex;
    padding: 2.8em 1.4em 1.4em 1.4em;
    border: 1px solid #ededed;
    border-bottom-width: 5px;
    border-radius: 5px;
    min-width: 300px;
    gap: 20px;
    flex: 0 1 calc(33.33% - 70px);
}

.solution-card_icon {
    width: 47px;
    height: auto;
}

.solutions-card_content span {
    margin: 0;
    color: #b53729;
    font-size: 1rem;
    font-weight: 600;
    font-family: var(--primary-font);
}