.partners-card {
    display: flex;
    flex-direction: row-reverse;
    /* align-items: center; */
    justify-content: start;
    padding: 1.4em 1.4em 1.4em 1.4em;
    border: 1px solid #ededed;
    border-bottom: 5px solid #b53729;
    border-radius: 5px;
    
    gap: 20px;
    flex: 0 1 calc(33.33% - 70px);
    background-color: white;
    margin-top: 20px;
}

.partners-card_image {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 150px;

}

.partners-card_image img {
    height: auto;
    width: auto;
    max-width: 150px;
    max-height: 150px;
}

.partners-card_line-separator {
    /* border-top: 1px solid #474747; */
    margin: 5px 0;
}

.partners-card_content span {
    margin: 0;
    color: #474747;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: var(--primary-font);
}
@media screen and (max-width: 850px){
    .partners-card{
        flex-direction: row;
    }
}
@media screen and (max-width: 620px){
    .partners-card{
        flex-direction: column;
    }
    .partners-card_image{
        margin: 3em 0;
    }
}