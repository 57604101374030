.industrial_text{
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8;
}
.industrial__break{
    height: 15px;
}
.industrial_text a{
    text-decoration: none;
    color: #1e73be;
    line-height: 1.8;
    transition: color .3s ease-in-out, box-shadow .4s ease-in-out;
}
.industrial_text a:hover {
    color: #808080;
}
.industrial-Content img {
    max-width: 100%;
}