.partners__title{
    height: auto;
    max-width: 100%;
    background-image: url(../../Images/ContactLanding.png);
    font-weight: 700;
    color: white;
    font-size: 50px;
    text-align: center;
    margin-top: 70px;
    padding-top: 7rem;
    padding-bottom: 7rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.partners__container {
    display: -webkit-flex;
    display: flex;
    /* align-items: flex-start; */
    justify-content: center;
    width: 100%;
    /* margin-top: 5%; */
    color: #474747;
    /* padding-bottom: 5%; */
    background-color: #fafafa;
    border-top: 1px solid #d7d7d7;
}

.partners__body {
    flex: 1;
    max-width: 1200px;
    width: 100%;
    min-height: 600px;
    padding: 0 15px;
}



