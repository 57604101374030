/* @import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('mainStyle.css'); */

.container{
    display: flex;
    max-width: 1200px;
    margin: 100px auto 30px auto;
    min-height: 300px;
    height: auto;
    color: #474747;
    align-items: flex-start;
    padding-bottom: 5%;
    gap: 4rem;
    padding-left: 2%;
}

.sidebar{
    width: 300px;
    max-width: 1200px;
}
