.distribution-container {
    width: 100%;
    margin-top: 70px;
}
.distribution-container img {
    width: 100%;
}

.distribution_content{
    display: flex;
    width: 100%;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    gap: 5px;
    padding: 0 20px;
        box-sizing: border-box;
}

.distribution_content .content_right-side{
    min-width: 270px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact-page_divider {
    background-color: #474747;
    opacity: 0.2;
    height: 1px;
    width: 100%;
    margin: 40px 0;
}
.distribution-container_intro{
    width: 100%;
    max-width: 1200px;
    padding: 0 20px 20px 0;
}

.distribution-container_intro h2{
    color: #b53729;
    font-size: 2.5rem;
    font-family: var(--primary-font);
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0;
}

.distribution-container_intro p {
    color: #474747;
    font-size: 1.2rem;
    font-family: var(--primary-font);
    font-weight: 400;
}

@media screen and (max-width:1060px) {
    .distribution_content {
        flex-direction: column;
    }

}