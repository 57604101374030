/* Styles for screens smaller than 600px */
@media (max-width: 600px) {
    /* Add your responsive styles here */
}

/* Styles for screens between 601px and 900px */
@media (min-width: 601px) and (max-width: 900px) {
    /* Add your responsive styles here */
}

/* Styles for screens larger than 900px */
@media (max-width: 901px) {
    /* Add your responsive styles here */
    .aboutus__contents{
        display: flex;
        flex-direction: column;
        gap: 0;
        box-sizing: border-box;
    }
    .cards{
        display: flex;
        flex-direction: column;
    }
    .advanced-section{
        display:flex;
        flex-direction: column-reverse;
    }
    .advanced-section img{
        max-width: 400px;
    }
}
