.solution-card {
    max-width: 227px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    margin: auto;
    font-size: 1rem;
    font-weight: 400;
    font-family: var(--primary-font);
}