.contact-page {
    margin-top: 70px;
}

.contact-page img {
    width: 100%;
}

.contact-page_container {
    box-sizing: border-box;
    width: 100%;
    max-width: 1200px;
    display: flex;
    gap: 1rem;
    margin: auto;
    justify-content: space-between;
    padding: 0 20px;

}

.contact-info {
    flex: 2;
    margin-bottom: 40px;
}

.git-in-touch {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;

}

.contact-page_title {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.contact-page_title h3 {
    font-family: var(--primary-font);
    font-size: 1.3rem;
    font-weight: 600;
    color: #3d3d3d;
}

.contact-page_title div {
    flex: 1;
    background-color: #474747;
    opacity: 0.2;
    margin: 0 0.8em;
    height: 1px;
}

.contact-page_divider {
    background-color: #474747;
    opacity: 0.2;
    height: 1px;
    width: 100%;
    margin: 40px 0;
}

.git-in-touch p{
    max-width: 350px;
    text-align: center;
}