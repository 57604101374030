.aboutus-container{
    display: flex;
    flex-wrap: wrap;
}
.introSlide{
    width: 100%;
    margin-top: 70px;
}
.aboutus__contents h2{
    font-size: 38px;
    color: #262626;
    line-height: 45px;
    text-align: left;
    flex: 1;
    font-weight: 700 !important;
    font-family: "CustomFont", Arial, Helvetica, sans-serif;
}
.aboutus__contents{
    max-width: 1200px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    gap: 50px;
    margin: 0 auto;
    padding: 80px 15px;
}
.aboutus__content {
    flex: 1;
    text-align: left;
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    color: #474747;
}
