.partners-section {
    /* box-sizing: border-box; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;
}

.partners-section_paragraph {
    width: 100%;
    max-width: 1170px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    justify-content: space-around;
    gap: 10px;
    flex-wrap: wrap;
}
.partners-section_paragraph img {
    max-width: 100%;
}

.partners-section_paragraph .paragraph {
    max-width: 570px;
    /* min-width: 360px; */
    font-size: 1.2rem;
    font-weight: 400;
    font-family: var(--primary-font);
}

.partners-section_logos {
    width: 100%;
    max-width: 1170px;
    display: flex;
    align-items: center;
    min-height: 45px;
    margin-top: 3rem;
    justify-content: space-around;
    gap: 10px;
    flex-wrap: wrap;
}

.partners-section_logos img {
    padding: 10px 0;
    flex: 1;
    max-height: 45px;
    margin: 0 5px;
    border: 2px solid #d6d6d6;
    border-radius: 10px;
}