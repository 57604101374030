.socail__bar{
    padding-top: 2%;
    padding-bottom: 2%;
    width: 100%;
    /* max-width: 230px; */
    /* max-height: 42px; */
    text-align: center;

}

.social__icon{
    width: 12%;
    max-width: 40px;
    text-align: center;
    padding: 1%;
    border-radius: 30%;
    max-height: 40px;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.3));
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
 }
 .social__icon:hover{
    filter: drop-shadow(0 0 0.20rem rgb(0 0 0 / 0.3));
    /* transition: all .4s ease;
    -webkit-transition: all .4s ease; */
 }
