.maintenance-content{
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8;
}
.maintenance-content a{
    text-decoration: none;
    color: #1e73be;
    line-height: 1.8;
    transition: color .3s ease-in-out, box-shadow .4s ease-in-out;
}
.maintenance-content a:hover {
    color: #808080;
}
.maintenance-content img {
    max-width: 100%;
}
.maintenance-content span {
    font-weight: bold;
}