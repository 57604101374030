*
{
    font-weight: 400;
}

h2{
    text-align: left;
}
.input__form
{
    font-size: 13px;
    margin-left: 5%;
    margin-right: 5%;
}

.th__right
{
    width: 90%;   
}

.th__left
{
    width: 10%;
    position:static;
    /* padding-top: 1%;
    padding-bottom: 1%; */
}

.label__floating
{
    position:inherit;
}

.input__area--name
{
    background-color: inherit;
    padding: 0.5rem;
    font-family:inherit;
}

.input__area
{
    background-color: inherit;
    padding: 0.5rem;
    font-family:inherit;
    /* transform: translateX(-3.5%); */
}

.input__area__icon
{
    color: #b3dbdd;
    font-size:14.1pt;
    border-color: #797979;
    border-width: 1px;
    border-style:solid;
    border-radius: 5px;
    padding: 0.5rem;
}

.message__area
{
    background-color: inherit;
    border-color: black;
    resize: vertical;
    padding-left: 15px;
    height:5rem;
    margin-top: 5%;
    padding-top: 10px;
}

.button__containter
{
    padding-left: 1rem;
}

.button
{
    /* remove default behavior */
    appearance:none;
    -webkit-appearance:none;
    /* styles */
    padding:0.8rem;
    position: relative;
    top:2rem;
    margin-bottom: 30px;
    border:none;
    background-color:#ad2727;
    color:white;
    font-size: 14px;
    font-weight:400;
    width:40%;
    /*Adding shadow for button*/
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.3));
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
}

.button:hover
{
    filter: drop-shadow(0 0 0.20rem rgb(0 0 0 / 0.3));
    transition: all .4s ease;
    -webkit-transition: all .2s ease;
    cursor: pointer;
    background-color: #44474e;
}

.select
{
    height: 2.3rem;
    text-align: center;
    font-family:inherit;
    width:102% !important;
    /* transform: translateX(2.5%); */
}

.input__area__wrp 
{
	position:sticky;
	width:100%;
    height: 4rem;
}

.input__area__wrp .input__area, 
.input__area__wrp .select, 
.input__area__wrp .input__area--name, 
.input__area__wrp .message__area 
{
	width: 100%;
	outline: none;
	/* border:none; */
    border-color: #cdcdcd;
	border-bottom: 1px solid #cdcdcd;
 	box-shadow: none !important;
    border-width: 0.2px;
    border-radius: 5px;
}

.input__area__wrp .input__area:focus, 
.input__area__wrp .select:focus, 
.input__area__wrp .input__area--name:focus, 
.input__area__wrp .message__area:focus
{
    border-width: 1px;
}


/*
    STYLE FOR THE INPUT LABELS
*/
.input__area__wrp .label__floating, 
.input__area__wrp .label__floating--name, 
.input__area__wrp .label__floating--textarea 
{
	position: absolute;
	pointer-events: none;
	transition: 0.1s ease all;
}


/*
    -STYLE FOR THE LABEL THAT FLOATS ON INPUTS WHEN FOCUSED.
    -label__floating--name IS FOR THE "NAME" INPUT.
    -THE label__floating IS FOR BOTH "EMAIL" AND "PHONE NUMBER" INPUT.
    -label__floating--textarea IS FOR TEXT AREA OF QUESTIONS.
*/
.input__area__wrp input:focus ~ .label__floating--name,
.input__area__wrp input:not(:focus):valid ~ .label__floating--name,
.input__area__wrp input:focus ~ .label__floating,
.input__area__wrp input:not(:focus):valid ~ .label__floating,
.input__area__wrp textarea:focus ~ .label__floating--textarea,
.input__area__wrp textarea:not(:focus):valid ~ .label__floating--textarea
{
	font-size: 11px;
	opacity: 1;
    color: #50afb4;
    padding-left: 4px;
    padding-right: 4px;
}

