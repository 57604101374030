.company-info {
    flex: 1;
    /* min-width: 310px; */
}

.company-info span {
    display: inline-block;
    color: #b53729;
    margin: 0;
    font-size: 1.1rem;
    font-family: var(--primary-font);
    /* margin-bottom: 10px; */
}

.company-info .company-info__contact-info {
    all: unset;
    list-style: none;
    display: flex;
    flex-direction: row;
}

.company-info .company-info__contact-info li {
    display: flex;
    /* margin-bottom: 10px; */
}

.company-info .company-info__contact-into_icon {
    display: block;
    font-size: 16px;
    line-height: 26px;
    color: #bebebe;
    padding-top: 2px;
    padding-right: 5px;
    padding-left: 15px;


}

.company-info__phones {
    padding-left: 0;
    direction: ltr;
}

.company-info__phones li {
    margin-bottom: 2px;
}

.company-info__contact-info li strong {
    padding-right: 10px;
}
.company-info p{
    text-align: justify;
}
@media screen and (max-width: 850px) {
    .company-info .company-info__contact-info {
        flex-direction: column;
    }
}