 /* Changing the order for the sidebar and the article after resizing the screen */
 @media screen and (min-width: 850px) {

     .partners__container {
         flex-direction: row-reverse;
     }

     .partners__container div:first-child {
         order: 1;
     }

     .partners__sidebar {
         /* margin-right: 10%; */
         /* margin-left: 10%; */
         min-width: 300px;
     }
 }

 @media screen and (max-width: 850px) {
     .partners__container {
         flex-direction: column;
     }

     .partners__sidebar {
         position: relative !important;
         width: 90%;

     }

     .partners__body {
         width: 100%;
         margin-left: 0;
         margin-right: 0;
     }
 }

 /* .partners__body, .partners__sidebar{
    flex-grow: 1;
} */