.partners-info__title{
    height: auto;
    max-width: 100%;
    background-image: url(../../Images/ContactLanding.png);
    font-weight: 700;
    color: white;
    font-size: 50px;
    text-align: center;
    margin-top: 70px;
    padding-top: 7rem;
    padding-bottom: 7rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.partners-info__container {
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    width: 100%;
    color: #474747;
    background-color: #fafafa;
    border-top: 1px solid #d7d7d7;
}

.partners-info__body {
    margin-top: 3em;
    flex: 1;
    max-width: 1200px;
    width: 100%;
    min-height: 600px;
    margin-bottom: 3em;
}

.table_description {
    margin-top: 2em;
    width: 100%;
    border-collapse: collapse;
    background-color: rgba(255, 255, 255, 0); 
}

.table_description th,
.table_description td {
    padding: 8px;
    text-align: left;
}

.table_description .right_head,
.table_description .right_data {
    padding: 8px;
    text-align: right;
}

.table_description .first_column {
    width: 20%;
    text-align: end;
}

.table_description .first_column_ar {
    width: 20%;
    text-align: end;
}


.dashed_line{
    margin-top: 2em;
    border: 1px dashed rgba(6, 5, 5, 0.2);
}