.smart-solution_container {
    box-sizing: border-box;
    background-color: #f7f7f7;
    width: 100%;
    padding: 4rem 2rem;
}

.smart-solution_container .container_title {
    font-size: 1.5rem;
    font-family: var(--primary-font);
    color: black;
    text-align: center;
    display: block;
    font-weight: 600;
    margin-bottom: 5rem;
}
.container_solution-list{
    display: flex;
    justify-content: space-around;
    gap: 10px;
    flex-wrap: wrap;

}
.container_solution-list h3{
    min-width: 150px;
    height: 9rem;

}