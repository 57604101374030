.title{
    color: #c13105;
    background-color: #ffffff;
    padding: 5px;
    text-transform: uppercase;
    font-size: 35px;
    font-weight: 400;
    display: flex;
	flex-basis: 100%;
	align-items: center;
	margin: 8px 0px;
	text-transform: uppercase;
}

.title::before,
.title::after {
	content: "";
	flex-grow: 1;
	background: rgba(0, 0, 0, 0.1);
	height: 1px;
	font-size: 0px;
	line-height: 0px;
	margin: 0px 8px;
}