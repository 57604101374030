.benefits_sector{
    display: flex;
    background-color: #1d2a33;
}
.benefits_sector_img {
    display: flex;
    flex: 1;
    flex-direction: column;
}
.benefits_sector img{
    max-height: 575px;
    max-width: 100%;
    object-fit: cover;
}
.benefits_sector_paragraph {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-left: 1em;
    align-content: flex-start;
}
.paragraph_card {
    display: flex;
    flex: 1;
    min-width: 260px;
}
.benefits-card{
    padding: 0.5rem;
}
.benefits_paragraph_icon {
    padding-top: 2rem;
    color: aliceblue;
}
.benefits_sector_paragraph h1{
    font-size: 1.3rem;
    color: aliceblue;
}
.benefits_sector_paragraph p {
    font-size: 1rem;
    color: #bebebe;
}
@media (max-width: 1200px) {
    .benefits_sector {
        display: flex;
        flex-direction: row-reverse;
    }
    .benefits_sector img{
        transform: scaleX(-1);
    }
    }
    @media (max-width: 760px) {
        .benefits_sector {
            display: flex;
            flex-direction: column-reverse;
        }
        }