@media screen and (min-width: 850px){
    .Commercial{
        flex-direction: row-reverse;
    }
    .Commercial div:first-child {
        order: 1;
    }
}

@media screen and (max-width: 850px){
    .Commercial{
        flex-direction: column;
    }
    .Commercial__sidebar{
        position: relative !important;
        width: 100%;
    }
    .Commercial__content{
        width: 100%;
    }
}
.Commercial__content, .Commercial__sidebar{
    flex-grow: 1;
}