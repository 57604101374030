@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

.sidebar{
    height: 1050px;
    text-align: center;
    margin-right: 1%;
}

.sidebar__title{
    text-align: center;
    background-color: #f4f4f4;
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 5px;
    padding-top: 5px;
    color:black
}
  
.sidebar__content__text{
    width: 100%;
    padding-bottom: 5px;
    padding-top: 5px;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.8;
}
  
.sticky {
    position: fixed;
    bottom: 0;
    top: auto;
}