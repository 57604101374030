.section {
	display: flex;
	flex-basis: 100%;
	align-items: center;
	color: rgba(0, 0, 0, 0.35);
	margin: 15px 0px;
    color: black;
}

.section::before{
	flex-grow: 1;
	background: rgba(0, 0, 0, 0.25);
	height: 1px;
	font-size: 0px;
	line-height: 0px;
	margin: 0px 0px;
}

.section::after {
	content: "";
	flex-grow: 1;
	background: rgba(0, 0, 0, 0.25);
	height: 1px;
	font-size: 0px;
	line-height: 0px;
	margin: 0px 8px;
}

.section__icon{
	display: block;
    width: 40px;
    height: 40px;
	padding-right: 0.5rem;
}

