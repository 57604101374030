.light {
    color: azure;
}

.slider-container {
    position: relative;
    width: 100%;
    z-index: 1;
    direction: ltr;
}

.slider {
    display: flex;
    overflow: hidden;
    width: 100%;
}

.slide {
    position: relative;
    /* transition: transform 100s; */
    transform: translateX(-100%);
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: none;
}

.slide img {
    position: absolute;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: -1;
}

.prev-btn,
.next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    z-index: 999;
    display: none;
}
.slider-container:hover .prev-btn,
.slider-container:hover .next-btn {
    display: block;
}
.prev-btn {
    left: 0;
    z-index: 99;
    height: 140px;
    direction: ltr;
}

.next-btn {
    height: 140px;
    right: 0;
    direction: ltr;
}

.active {
    transform: translateX(0);
    display: flex;
}

.slide_content {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0  25px ;
    justify-content: center;
    min-height: 650px; 
}

.content_card {
    max-width: 500px;
}

.content_card span {
    font-size: 3rem;
    line-height: 4rem;
    font-weight: 600;
}

.content_card p {
    font-size: 1.2rem;
    line-height: 1.8rem;
}

.content_card button {
    background-color: rgb(181, 55, 41);
    font-family: Tajawal;
    line-height: 2.1rem;
    border-color: transparent;
    border-radius: 3px;
    color: white;
    padding: 0 2em;
    transition: 0.8s;
    cursor: pointer;
}

.content_card button:hover {
    background-color: rgb(111, 108, 108);
}

.slide_content-positioning {
    flex: 1;
}