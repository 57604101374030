.city-section {
    flex: 1;
        min-width: 310px;
}
.city-section span{
    display:inline-block;
    color: #b53729;
    margin: 0;
    font-size: 1.1rem;
    font-family: var(--primary-font);
    margin-bottom: 10px;
}

.city-section .contact-info {
    all: unset;
    list-style: none;
}

.city-section .contact-info li {
    display: flex;
    margin-bottom: 10px;
}

.city-section .contact-into_icon {
    display: block;
    font-size: 16px;
    line-height: 26px;
    color: #bebebe;
    padding-top: 5px;
    padding-right: 10px;
    padding-left: 10px;


}

.phones {
    padding-left: 0;
    direction: ltr;
}

.phones li {
    margin-bottom: 2px;
}

.contact-info li strong {
    padding-right: 10px;
}