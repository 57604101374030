.title-container{
    height: auto;
    max-width: 100%;

    font-weight: 700;
    color: #474747;
    font-size: 50px;
    text-align: center;
    padding-top: 12rem;
    padding-bottom: 4rem;

}
.title-text{


}