.sidenav-container {
    display: flex;
    flex: 1;
    width: 100%;
}

.sidenav-container_navigation {
    min-width: 266px;
        flex: 1;
    box-sizing: border-box;
    overflow-y: auto;
}

.sidenav-container_navigation .nav-item {
    display: block;
    margin: 1px 0;
    cursor: pointer;
    transition: background .2s ease-in-out, color .2s ease-in-out, border .2s ease-in-out;

    border-color: #e3e3e3;
    border-width: 1px;
    border-top-left-radius: 5px;
    border-top-left-radius: 5px;
    border: solid transparent;
    background-color: #ebebeb;
    padding: 14px 20px;
    color: #666;
    width: 100%;
    text-align: start;
    font-size: 1.2rem;
}
.sidenav-content-right {
    display: flex;
    flex-direction: column;
}
.content-content {
    min-height: 25px;
    display: flex;
    align-items: center;
}

.sidenav-container_navigation .nav-item:hover,
.nav-item.active {
    background-color: #f8f8f8;
}

.sidenav-container_content {
    flex: 2;
    background-color: #f8f8f8;
    padding: 0 14px;
    box-sizing: border-box;
    transition: transform 10s ease;
}

.sidenav-container .content-item_title {
    color: #b53729;
    font-size: 2rem;
    font-family: var(--primary-font);
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0;
}

.sidenav-container .content-item_subtitle {
    font-size: 1.4rem;
    font-family: var(--primary-font);
    font-weight: bold;
    margin: 0.5em 0;
    color: #474747;
}
.sidenav-container .content-content_icon {
    min-width: 22px;
    margin-right: 7px;
    margin-left: 7px;
}
.do-not-display {
    display: none;
}

.content-active {
    display: block;
}